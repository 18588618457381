import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const TopbarLogo = styled.img`
  width: 125px;
`;

// Navbar Container
export const Navbar = styled.nav.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant?: "dark" | "light" }>`
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid ${colorPalette.blue200};
  background-color: white;
  height: 70px;
  z-index: 10;

  @media (max-width: 992px) {
    height: auto;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-bottom: ${({ themeVariant }) =>
      themeVariant === "dark"
        ? "none"
        : `1px solid ${colorPalette.blue200}`} !important;
    background-color: ${({ themeVariant }) =>
      themeVariant === "dark"
        ? colorPalette.primaryDark
        : "rgba(255, 255, 255, 0.8)"} !important;
    backdrop-filter: blur(20px) !important;
  }
`;

// Navbar Title
export const NavbarTitle = styled.span`
  color: ${colorPalette.blue600};
  ${typeScale.bodyMDMain}
`;

// Search Container
export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

// Search Input
export const SearchInput = styled.input`
  background-color: #f3f3f3;
  color: #b1b5c1;
  font-size: 14px;
  padding-left: 40px;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s;
  height: 40px;

  &:focus {
    background-color: #f3f3f3;
    color: #b1b5c1;
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
  }

  &::placeholder {
    color: #b1b5c1;
  }
`;

// Search Icon
/*export const SearchIcon = styled(BsSearch)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #b1b5c1;
  z-index: 10;

  @media (max-width: 992px) {
    color: white;
  }
`;*/

// User Avatar
export const Avatar = styled.img.withConfig({
  shouldForwardProp: (prop) => prop !== "showBorder",
})<{ showBorder: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;

  ${({ showBorder }) =>
    showBorder &&
    `
    border: 3px solid ${colorPalette.blue500};
  `}
`;

// Initials Avatar
export const InitialsAvatar = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showBorder",
})<{ showBorder: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colorPalette.primaryDark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${colorPalette.blue400};
  ${typeScale.headingXXSMain};

  ${({ showBorder }) =>
    showBorder &&
    `
    border: 3px solid ${colorPalette.blue500};
  `}
`;

// Styled for the Avatar dropdown container
export const AvatarDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 250px;
  background-color: ${colorPalette.white};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  padding: 8px 0;

  /* Arrow pointing to the avatar */
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid ${colorPalette.white};
  }
`;

// Individual dropdown items
export const AvatarDropdownItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};

  &:not(:last-child) {
    border-bottom: 1px solid ${colorPalette.blue200};
  }

  &:hover {
    background-color: ${colorPalette.blue50};
  }
`;

export const DropdownItemLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};
`;

export const DropdownHeader = styled.div`
  display: block;
  border-bottom: 1px solid ${colorPalette.blue200};
  padding: 12px 16px;
`;

export const DropdownTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXXSMain}
`;

export const DropdownSubtitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;

/* PROGRESS BAR */
export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${colorPalette.blue50};
  position: relative;
`;

export const Progress = styled.div<{ width: number }>`
  height: 100%;
  background-color: ${colorPalette.blue500};
  width: ${({ width }) => width}%;
  transition: width 0.3s ease;
`;
