// FAQCategories.tsx (component file)
import React from 'react';
import {
    Container,
    Header,
    FAQItem,
    Title,
    Description,
    HeaderTitle,
    IconContainer,
    HeaderSubtitle,
    BottomSection,
    TopSection,
} from './faq-categories.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import { MaterialSymbol } from '@material-symbols/font-400';
import { Col, Row } from 'react-bootstrap';

export interface FAQCategoryItem {
    id: number;
    title: string;
    description: string;
    icon: MaterialSymbol;
}

export const FAQ_CATEGORIES: FAQCategoryItem[] = [
    {
        id: 1,
        title: "Immobilienkauf",
        description: "Was du vor dem Kauf wissen musst",
        icon: "in_home_mode"
    },
    {
        id: 2,
        title: "Projektübergabe",
        description: "Wie deine Immobilie an dich übergeben wird",
        icon: "key"
    },
    {
        id: 3,
        title: "Mietverwaltung",
        description: "Deine Vorteile mit einer verwalteten Wohnung",
        icon: "real_estate_agent"
    },
    {
        id: 4,
        title: "Vermietung",
        description: "Wie wir den perfekten Mieter finden",
        icon: "location_away"
    },
];

const FAQCategories: React.FC = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    const goToFAQWithCategory = (categoryId: number) => {
        navigate(`/faq?categoryId=${categoryId}`);
    };

    const renderHeader = (
        <>
            {isMobile ? (
                <Header>
                    <div className="d-flex align-items-center" style={{ gap: 4 }} onClick={isMobile ? () => navigate("/faq") : undefined}>
                        <HeaderTitle>Häufige Fragen</HeaderTitle>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </div>
                    <HeaderSubtitle>Wähle das Thema aus zu dem du eine Frage hast:</HeaderSubtitle>
                </Header>
            ) : (
                <Header>
                    <div className="d-flex align-items-center justify-content-between" onClick={isMobile ? () => navigate("/faq") : undefined}>
                        <HeaderTitle>Häufige Fragen</HeaderTitle>
                        <CTAButton
                            variant="ghost"
                            size="S"
                            label="Alle Fragen anzeigen"
                            icon="arrow_outward"
                            onClick={() => navigate("/faq")}
                            noPadding
                        />
                    </div>
                    <HeaderSubtitle>Wähle das Thema aus zu dem du eine Frage hast:</HeaderSubtitle>
                </Header>
            )}
        </>
    );

    return (
        <Container>
            {renderHeader}
            <Row className={isMobile ? "gy-2 gx-2" : "gy-3 gx-3"}>
                {FAQ_CATEGORIES.map((category) => (
                    <Col xs={6} md={4} xl={3} key={category.id}>
                        <FAQItem onClick={() => goToFAQWithCategory(category.id)}>
                            <TopSection>
                                <IconContainer>
                                    <MaterialIcon icon={category.icon} size={36} color={colorPalette.blue400} />
                                </IconContainer>
                            </TopSection>
                            <BottomSection>
                                <Title>{category.title}</Title>
                                <Description>{category.description}</Description>
                            </BottomSection>
                        </FAQItem>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default FAQCategories;
