import React from 'react';
import {
    BannerContainer,
    BannerMainWrapper,
    BannerImageWrapper,
    BannerContentWrapper,
    BannerText,
    BannerSubText,
    BannerButtonWrapper,
    Container,
} from './more-questions-banner.styles';
import CTAButton from '../../../../components/cta-button/cta-button';
import AloneWaving from '../../../../assets/images/alone_waving.webp';
import TogetherWaving from '../../../../assets/images/together_waving.webp';
import useIsMobile from '../../../../hooks/useIsMobile';
import useContactUsModalStore from '../../../../stores/contactUsModalStore';

const MoreQuestionsBanner: React.FC = () => {
    const isMobile = useIsMobile();

    // Stores
    const setIsContactUsModalVisible = useContactUsModalStore(state => state.setIsVisible);

    const onClickContactUs = () => {
        setIsContactUsModalVisible(true);
    }

    return (
        <Container>
            <BannerContainer>
                <BannerMainWrapper>
                    <BannerImageWrapper>
                        <img src={isMobile ? AloneWaving : TogetherWaving} alt="Waving" />
                    </BannerImageWrapper>
                    <BannerContentWrapper>
                        <BannerText>Hast du weitere Fragen?</BannerText>
                        <BannerSubText>
                            Falls du weitere Fragen hast, die hier nicht geklärt werden konnten, kontaktiere uns gerne!
                        </BannerSubText>
                    </BannerContentWrapper>
                </BannerMainWrapper>

                {/* Desktop Button */}
                <BannerButtonWrapper className="d-none d-md-inline">
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Kontaktiere uns"
                        onClick={onClickContactUs}
                    />
                </BannerButtonWrapper>

                {/* Mobile Button */}
                <CTAButton
                    className="d-md-none mt-2"
                    variant="primary"
                    size="S"
                    label="Kontaktiere uns"
                    onClick={onClickContactUs}
                    fullWidth
                />
            </BannerContainer>
        </Container>
    );
};

export default MoreQuestionsBanner;
