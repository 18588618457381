// FAQPage.tsx
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import useIsMobile from "../../../../hooks/useIsMobile";
import { colorPalette } from "../../../../utils/theme";
import { StyledContainer } from "../../knowledge-hub.styles";
import { HeaderContainer, Title, FormSection } from "./faq-page.styles";
import FAQS, { FAQItem } from "../../components/faqs/faqs";
import useCustomNavigate from "../../../../hooks/useCustomNavigate";
import MoreQuestionsBanner from "../../components/more-questions-banner/more-questions-banner";
import { useEffect, useRef, useState } from "react";
import { FAQ_CATEGORIES } from "../../components/faq-categories/faq-categories";
import { useLocation } from "react-router-dom";

const faqs: FAQItem[][] = [
    [
        {
            question: "Was ist der Unterschied zwischen Off-Plan-Immobilien und Bestandsimmobilien?",
            answer: "Off-Plan-Immobilien befinden sich noch in der Bauphase und werden oft zu einem günstigeren Preis angeboten. Käufer erwerben hier quasi das Konzept, mit der Aussicht auf ein fertiges Objekt in der Zukunft. Bestandsimmobilien hingegen sind bereits fertiggestellt, können direkt genutzt oder vermietet werden und bieten einen sofortigen Einblick in Zustand und Lage. Beide Optionen haben ihre Vorzüge und richten sich an unterschiedliche Käuferbedürfnisse."
        },
        {
            question: "Welche Vorteile bietet der Kauf einer Off-Plan-Immobilie?",
            answer: "Der Kauf einer Off-Plan-Immobilie bietet oft günstigere Einstiegskosten, da die Preise während der Bauphase niedriger sind. Zusätzlich ermöglichen flexible Zahlungspläne eine bessere Budgetplanung. Ein weiterer Vorteil ist das potenzielle Kapitalwachstum, da der Immobilienwert während des Bauprozesses steigen kann."
        },
        {
            question: "Welche Risiken gibt es bei Off-Plan-Immobilien?",
            answer: "Off-Plan-Investitionen bergen Risiken wie Bauverzögerungen, Änderungen im Design und eine Abhängigkeit von der Zuverlässigkeit des Bauträgers. Es ist wichtig, mit erfahrenen Bauträgern zusammenzuarbeiten und sicherzustellen, dass das Projekt von Behörden wie der RERA (Real Estate Regulatory Agency) genehmigt ist."
        },
        {
            question: "Welche Unterlagen benötige ich, um eine Immobilie in den VAE zu kaufen?",
            answer: "In der Regel benötigen Sie eine Kopie Ihres Reisepasses, Zahlungsnachweise und – falls zutreffend – eine gültige Aufenthaltsgenehmigung. Manche Projekte erfordern zusätzliche Dokumente, insbesondere bei Finanzierung."
        },
        {
            question: "Wie wird die Zahlung abgewickelt?",
            answer: "Zahlungen erfolgen häufig in Raten, die auf den Baufortschritt abgestimmt sind. Diese werden an ein Treuhandkonto (Escrow) überwiesen, der von den Behörden überwacht wird, um die Sicherheit des Käufers zu gewährleisten. Das Treuhandkonto stellt sicher, dass Ihr Geld nur für das Projekt verwendet wird."
        },
        {
            question: "Kann ich eine Immobilie kaufen, wenn ich nicht in den VAE lebe?",
            answer: "Ja, ausländische Investoren können in bestimmten Freehold-Zonen Immobilien erwerben. Diese Gebiete sind speziell dafür vorgesehen, internationalen Käufern Eigentumsrechte zu gewähren."
        },
        {
            question: "Sind Off-Plan-Immobilien eine sichere Investition?",
            answer: "Mit einem etablierten Bauträger und der strengen Kontrolle durch Behörden wie RERA gelten Off-Plan-Immobilien als sichere Investition. Es ist wichtig, den Ruf des Bauträgers zu prüfen und die Vertragsbedingungen genau zu verstehen."
        },
        {
            question: "Welche Steuern fallen beim Immobilienkauf in den VAE an?",
            answer: "Die Hauptabgabe ist die Registrierungsgebühr, die 4 % des Immobilienwertes beträgt und an das Dubai Land Department (DLD) zu zahlen ist. Weitere Gebühren können für Dienstleistungen wie Makler oder notarielle Beglaubigungen anfallen."
        },
        {
            question: "Was sollte ich beim Kauf einer Bestandsimmobilie beachten?",
            answer: "Überprüfen Sie die Eigentumsurkunde, bestehende Mietverträge und ausstehende Gebühren wie Wartungs- oder Servicegebühren. Der Kaufprozess ist bei Bestandsimmobilien oft komplexer, da mehr Parteien involviert sind. Im Vergleich dazu ist der Kauf einer Off-Plan-Immobilie häufig klarer geregelt."
        },
        {
            question: "Wie finde ich den richtigen Bauträger?",
            answer: "Recherchieren Sie den Ruf des Bauträgers, prüfen Sie frühere Projekte und lesen Sie Bewertungen anderer Käufer. Wählen Sie Bauträger mit einer nachgewiesenen Erfolgsbilanz und einer transparenten Kommunikation."
        },
    ],
    [
        {
            question: "Was ist der Unterschied zwischen Off-Plan-Immobilien und Bestandsimmobilien?",
            answer: "Off-Plan-Immobilien befinden sich noch in der Bauphase und werden oft zu einem günstigeren Preis angeboten. Käufer erwerben hier quasi das Konzept, mit der Aussicht auf ein fertiges Objekt in der Zukunft. Bestandsimmobilien hingegen sind bereits fertiggestellt, können direkt genutzt oder vermietet werden und bieten einen sofortigen Einblick in Zustand und Lage. Beide Optionen haben ihre Vorzüge und richten sich an unterschiedliche Käuferbedürfnisse."
        },
        {
            question: "Was ist der Snagging-Prozess und warum ist er wichtig?",
            answer: "Snagging ist der Prozess, bei dem Mängel an einer Immobilie identifiziert werden, bevor sie offiziell an den Eigentümer übergeben wird. Dazu zählen kleine Fehler wie Farbunregelmäßigkeiten, aber auch größere Mängel wie undichte Leitungen oder elektrische Probleme. Dieser Schritt stellt sicher, dass die Immobilie in einem einwandfreien Zustand übergeben wird. Mit der Unterstützung von Managd profitieren Sie von einem professionellen Snagging-Service, der potenzielle Probleme frühzeitig erkennt und behebt."
        },
        {
            question: "Wie lange dauert die Übergabe einer Immobilie?",
            answer: "Die Dauer der Übergabe hängt von verschiedenen Faktoren ab, wie dem Fertigstellungsgrad der Immobilie und der Behebung eventueller Mängel. In der Regel kann dieser Prozess mehrere Wochen dauern. Managd koordiniert den gesamten Ablauf effizient, damit die Übergabe so reibungslos und schnell wie möglich abläuft."
        },
        {
            question: "Welche Zahlungen sind vor der Übergabe fällig?",
            answer: "Vor der Übergabe müssen alle ausstehenden Zahlungen beim Bauträger beglichen werden. Dazu gehören die letzten Raten des Kaufpreises, Servicegebühren und eventuell anfallende Strafen."
        },
        {
            question: "Wer übernimmt die Anmeldung bei den Versorgungsunternehmen?",
            answer: "Die Anmeldung bei Versorgungsunternehmen wie DEWA (Dubai Electricity and Water Authority) kann zeitaufwändig und komplex sein. Managd bietet Ihnen einen umfassenden Service, der diese Anmeldungen vollständig übernimmt. Dadurch sparst du Zeit und vermeidest mögliche Fehler im Anmeldeprozess."
        },
        {
            question: "Warum müssen Versorgungsanschlüsse wie DEWA aktiviert werden?",
            answer: "Aktive Versorgungsanschlüsse sind eine Voraussetzung für die Übergabe, da sie den reibungslosen Betrieb der Immobilie gewährleisten. Ohne diese Aktivierungen kann der Bauträger die Übergabe nicht abschließen. Managd übernimmt für dich die Koordination der Aktivierung und sorgt dafür, dass alles bereit ist, um die Immobilie zu vermieten."
        },
        {
            question: "Wann wird die Eigentumsurkunde ausgestellt?",
            answer: "Die Eigentumsurkunde wird ausgestellt, nachdem alle Zahlungen abgeschlossen und die Immobilie beim Dubai Land Department registriert wurde. Managd verfolgt den Status der Registrierung und hält dich über jeden Schritt auf dem Laufenden, bis die Urkunde offiziell vorliegt."
        },
        {
            question: "Welche Dokumente benötige ich für die Übergabe?",
            answer: "Für die Übergabe benötigst du Zahlungsnachweise, eine Kopie deines Reisepasses und Registrierungsdokumente. Managd sorgt dafür, dass alle Unterlagen korrekt vorbereitet und rechtzeitig eingereicht werden, um eine problemlose Übergabe zu gewährleisten."
        },
        {
            question: "Kann ich die Übergabe aus dem Ausland steuern?",
            answer: "Ja, mit einer Vollmacht (Power of Attorney, PoA) kann Managd den gesamten Übergabeprozess für dich abwickeln. Wir stellen sicher, dass deine Interessen vor Ort vertreten werden und du aus der Ferne über jeden Schritt informiert bist."
        },
        {
            question: "Was passiert, wenn Mängel nicht rechtzeitig behoben werden?",
            answer: "Sollten Mängel nicht rechtzeitig behoben werden, setzt sich Managd direkt mit dem Bauträger in Verbindung, um eine schnelle Lösung zu finden. Unser Team überwacht den Fortschritt der Reparaturen und sorgt dafür, dass die Immobilie in einem Zustand übergeben wird, der deinen Erwartungen entspricht."
        },
        {
            question: "Welche Vorteile habe ich mit Managd während der Übergabe?",
            answer: "Managd übernimmt alle Schritte der Übergabe vor Ort, einschließlich der Mängelprüfung, der Kommunikation mit dem Bauträger und der Anmeldung bei Versorgungsunternehmen. Dadurch sparst du wertvolle Zeit, minimierst Risiken und hast die Gewissheit, dass die Übergabe reibungslos und professionell abgewickelt wird."
        }
    ],
    [
        {
            question: "Was beinhaltet die Verwaltung meiner Immobilie?",
            answer: "Unsere professionelle Immobilienverwaltung umfasst ein Rundum-sorglos-Paket, das alle wesentlichen Aspekte abdeckt: Mieterbetreuung, Instandhaltung, Finanzmanagement und die Kommunikation mit relevanten Behörden. Dadurch stellen wir sicher, dass deine Immobilie nicht nur optimal bewirtschaftet, sondern auch langfristig im Wert erhalten bleibt. Unsere Services sind darauf ausgerichtet, dir maximale Entlastung und Sicherheit zu bieten."
        },
        {
            question: "Wie kümmert sich Managd um die Instandhaltung?",
            answer: "Managd organisiert und überwacht alle Aspekte der Instandhaltung deiner Immobilie. Das umfasst die Koordination von Reparaturen, die regelmäßige Wartung und routinemäßige Inspektionen. Unser Ziel ist es, den Wert deiner Immobilie nachhaltig zu sichern und potenzielle Probleme proaktiv zu identifizieren und zu lösen. Unser Netzwerk von erfahrenen Dienstleistern garantiert eine effiziente und qualitativ hochwertige Umsetzung."
        },
        {
            question: "Kann ich die Verwaltung aus dem Ausland steuern?",
            answer: "Ja, mit unserem digitalen Vermieterportal behältst du stets die volle Kontrolle – ganz gleich, wo du dich befindest. Du hast rund um die Uhr Zugriff auf alle relevanten Informationen, Berichte und Dokumente in Echtzeit. So bist du immer bestens informiert – unkompliziert, transparent und ortsunabhängig."
        },
        {
            question: "Wie sorgt Managd für die Einhaltung der Mietverträge?",
            answer: "Wir übernehmen die lückenlose Überwachung der Mietzahlungen, verwalten Kautionen und greifen umgehend bei Mietausfällen ein. Unsere erfahrenen Mitarbeiter sorgen dafür, dass alle vertraglichen Verpflichtungen eingehalten werden, und handeln in deinem besten Interesse – diskret, effizient und rechtssicher."
        },
        {
            question: "Was passiert bei Streitigkeiten mit dem Mieter?",
            answer: "Sollte es zu Konflikten kommen, übernimmt unser erfahrenes Team die gesamte Kommunikation mit dem Mieter. In Zusammenarbeit mit unserer Partnerkanzlei DGMA Legal setzen wir deine Rechte vor Ort durch. Wir sorgen für eine professionelle und rechtlich abgesicherte Lösung, damit du dir keine Sorgen machen musst."
        },
        {
            question: "Wie erhalte ich Updates zu meiner Immobilie?",
            answer: "Das Managd-Portal bietet dir umfassende Transparenz: Du erhältst regelmäßige Berichte zu Finanzen, Wartungsarbeiten und Mietverhältnissen. Mit unserer digitalen Plattform hast du jederzeit Zugriff auf den aktuellen Status deiner Immobilie – übersichtlich, sicher und bequem."
        },
        {
            question: "Was kostet die Verwaltung meiner Immobilie?",
            answer: "Unsere Services sind auf deine Bedürfnisse abgestimmt und werden über zwei attraktive Pakete angeboten:\nBasic-Paket: Nur 5 % der Jahresmiete – ideal für eine effiziente Basisverwaltung.\nPremium-Paket: 7 % der Jahresmiete – für umfassende Betreuung und erweiterten Service.\nMit diesen klar kalkulierten Optionen kannst du sicher sein, dass du den besten Gegenwert für dein Investment erhältst."
        },
        {
            question: "Wer ist mein Ansprechpartner?",
            answer: "Ein dediziertes Team steht dir und deinen Mietern zur Seite. Unsere Ansprechpartner vor Ort kümmern sich persönlich um deine Anliegen und sorgen dafür, dass alles reibungslos abläuft. So profitierst du von einem direkten Draht zu Experten, die deine Immobilie und deren Anforderungen genau kennen."
        },
        {
            question: "Wie sicher ist die Verwaltung meiner Einnahmen?",
            answer: "Wir legen höchsten Wert auf finanzielle Sicherheit und Transparenz. Alle Transaktionen werden über unabhängige Konten abgewickelt, sodass deine Einnahmen jederzeit geschützt sind. Unsere Prozesse sind darauf ausgelegt, Höchstmaß an Vertrauen und Kontrolle zu bieten."
        },
        {
            question: "Was passiert, wenn ein Mieter auszieht?",
            answer: "Managd übernimmt den gesamten Prozess: Von der professionellen Einzugs- und Auszugsinspektion bis hin zur Suche nach einem neuen, passenden Mieter. Unsere zielgerichteten Marketingstrategien und umfassenden Screening-Prozesse stellen sicher, dass deine Immobilie nahtlos weitervermietet wird. So minimieren wir Leerstandszeiten und sichern deine Rendite."
        }
    ],
    [
        {
            question: "Wie lange dauert es, einen Mieter zu finden?",
            answer: "Dank gezieltem Marketing und unserem umfassenden Netzwerk können wir in der Regel innerhalb weniger Wochen einen passenden Mieter für deine Immobilie finden. Unser zielgerichteter Ansatz mit einer Repräsentanz im Gebäude minimiert Leerstandszeiten und maximiert deine Rendite."
        },
        {
            question: "Wie erfolgt die Mietersuche?",
            answer: "Unsere Mietersuche erfolgt über eine Vielzahl von Kanälen: Wir nutzen Social Media, führende Immobilienportale sowie Besichtigungen und Open-House-Veranstaltungen. Durch eine ständige Vertretung vor Ort stellen wir sicher, dass deine Immobilie bestmöglich präsentiert wird und wir schnell den richtigen Mieter finden."
        },
        {
            question: "Wie überprüft Managd potenzielle Mieter?",
            answer: "Wir überprüfen potenzielle Mieter durch Bonitätsprüfungen, Gehaltsnachweise und Referenzen. So stellen wir sicher, dass die neuen Mieter zuverlässig und finanziell stabil sind. Unser sorgfältiger Prüfprozess bietet dir zusätzliche Sicherheit."
        },
        {
            question: "Was beinhaltet der Mieterservice?",
            answer: "Unser Mieterservice bietet umfassende Unterstützung: von der Überwachung des Einzugs über die Anmeldung von Versorgungsanschlüssen bis hin zur regelmäßigen Wartung. Zudem steht deinen Mietern ein dauerhafter Ansprechpartner vor Ort zur Verfügung, um eine reibungslose Kommunikation zu gewährleisten."
        },
        {
            question: "Wer erstellt den Mietvertrag?",
            answer: "Wir übernehmen die Erstellung von rechtssicheren Mietverträgen, die den lokalen Gesetzen und Vorschriften entsprechen. Dabei achten wir darauf, dass alle wichtigen Details berücksichtigt werden, um sowohl Vermieter als auch Mieter optimal abzusichern."
        },
        {
            question: "Wie werden Mietzahlungen verwaltet?",
            answer: "Mietzahlungen werden über unser modernes Buchhaltungssystem verwaltet, das dir volle Transparenz und Echtzeit-Einblicke bietet. Du kannst jederzeit alle Transaktionen überprüfen und behältst so stets die Kontrolle über deine Finanzen."
        },
        {
            question: "Was passiert bei Mietausfällen?",
            answer: "Im Falle von Mietausfällen übernehmen wir das komplette Forderungsmanagement. Dazu gehören die Versendung von Mahnungen und, falls erforderlich, die Einleitung rechtlicher Schritte (exkl. Gerichtsgebühren). So minimieren wir finanzielle Risiken für dich."
        },
        {
            question: "Wie kann ich als Vermieter von Deutschland aus helfen?",
            answer: "Mit Managd brauchst du dir keine Sorgen machen. Wir regeln alles vor Ort für dich. Unsere umfassenden Services und digitale Plattform ermöglichen es dir, dich entspannt zurückzulehnen und uns die Arbeit zu überlassen."
        },
        {
            question: "Welche Rolle spielt das Vermieterportal?",
            answer: "Unser Vermieterportal ist dein zentraler Hub für Transparenz und Kontrolle. Es bietet dir Echtzeit-Updates zu deinem Mietverhältnis sowie Zugriff auf wichtige Dokumente und Berichte. So bist du immer auf dem neuesten Stand."
        },
        {
            question: "Welche Vorteile hat die Zusammenarbeit mit Managd?",
            answer: "Die Zusammenarbeit mit Managd bietet dir Effizienz, Sicherheit und einen zuverlässigen Service vor Ort. Unsere professionelle Betreuung sorgt dafür, dass du maximale Rendite aus deiner Immobilie erzielst, während wir dir den gesamten Aufwand abnehmen."
        }
    ]
];


const FAQPage = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();
    const location = useLocation();

    // States
    const [expandedCategory, setExpandedCategory] = useState<number | null>(null);

    // References for each category
    const faqRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());

    // Effects
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const category = searchParams.get("categoryId");

        if (category) {
            const categoryId = Number(category);
            setExpandedCategory(categoryId);

            setTimeout(() => {
                const targetRef = faqRefs.current.get(categoryId);
                if (targetRef) {
                    targetRef.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }, 200);
        }
    }, [location.search]);

    return (
        <StyledContainer>
            <Row>
                <Col xs={12}>
                    <HeaderContainer>
                        {!isMobile && (
                            <MaterialIcon
                                icon="arrow_back"
                                size={24}
                                color={colorPalette.textMain}
                                onClick={() => navigate(-1)}
                            />
                        )}
                        <Title>Häufige Fragen</Title>
                    </HeaderContainer>

                    <FormSection className="gy-3">
                        <Col xs={12}>
                            {faqs.map((faqCategory, index) => (
                                <div
                                    ref={el => {
                                        faqRefs.current.set(FAQ_CATEGORIES[index].id, el);
                                    }}
                                    key={FAQ_CATEGORIES[index].id}
                                >
                                    <FAQS
                                        faqCategory={FAQ_CATEGORIES[index]}
                                        faqs={faqCategory}
                                        showButton
                                        isExpanded={expandedCategory === FAQ_CATEGORIES[index].id}
                                    />
                                </div>
                            ))}
                        </Col>
                    </FormSection>
                </Col>
            </Row>

            <MoreQuestionsBanner />
        </StyledContainer>
    );
};

export default FAQPage;
