import styled from "styled-components";
import { colorPalette, fonts, typeScale } from "../../utils/theme";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const ModalContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isClosing",
})<{ isClosing: boolean }>`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  width: auto;
  z-index: 10000;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    width: 100%;
    max-width: 900px;
  }

  /* Mobile-specific styles for bottom modal sheet */
  @media (max-width: 767.98px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 80vh;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    animation: ${({ isClosing }) =>
      isClosing ? "slideDown 0.3s ease-out" : "slideUp 0.3s ease-out"};

    @keyframes slideUp {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }

    @keyframes slideDown {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(100%);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingXSMain};
`;

export const ModalBody = styled.div`
  text-align: start;
  overflow-y: auto;
  max-height: 70vh;

  /* Apply max-height only on larger screens */
  @media (min-width: 768px) {
    max-height: 600px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 16px 24px 32px 24px;
`;

export const Title = styled.h4`
  margin-bottom: 16px;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSM};
  }
`;

export const Description = styled.p`
  margin-bottom: 32px;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;
