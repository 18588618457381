import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IConstructionUpdate } from "../services/constructionUpdateService";

const STORAGE_NAME = "construction-update-storage";

interface ConstructionUpdateState {
  updates: IConstructionUpdate[];
  currentPage: number;
  selectedPropertyId: number;
  isLoading: boolean;
  isInitialLoaded: boolean;
  moreDataAvailable: boolean;
  setUpdates: (updates: IConstructionUpdate[]) => void;
  setCurrentPage: (page: number) => void;
  setSelectedPropertyId: (propertyId: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitialLoaded: (isInitialLoaded: boolean) => void;
  setMoreDataAvailable: (moreData: boolean) => void;
  reset: () => void;
}

const useConstructionUpdateStore = create<ConstructionUpdateState>()(
  devtools(
    persist(
      (set) => ({
        updates: [],
        currentPage: 1,
        selectedPropertyId: 3,
        isLoading: true,
        isInitialLoaded: false,
        moreDataAvailable: true,
        setUpdates: (updates: IConstructionUpdate[]) => set({ updates }),
        setCurrentPage: (page: number) => set({ currentPage: page }),
        setSelectedPropertyId: (propertyId: number) =>
          set({ selectedPropertyId: propertyId }),
        setIsLoading: (isLoading: boolean) => set({ isLoading: isLoading }),
        setIsInitialLoaded: (isInitialLoaded: boolean) =>
          set({ isInitialLoaded: isInitialLoaded }),
        setMoreDataAvailable: (moreData: boolean) =>
          set({ moreDataAvailable: moreData }),
        reset: () => {
          set({
            updates: [],
            selectedPropertyId: 3,
            isLoading: true,
            isInitialLoaded: false,
          });
        },
      }),
      {
        name: STORAGE_NAME,
      }
    )
  )
);

export default useConstructionUpdateStore;
