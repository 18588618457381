import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const MobileContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
`;

// Header Title
export const HeaderTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colorPalette.blue200};
`;
