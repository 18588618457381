// ContactUsModalStore.ts
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ContactUsModalStoreState {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const useContactUsModalStore = create<ContactUsModalStoreState>()(
  devtools((set) => ({
    isVisible: false,
    setIsVisible: (isVisible: boolean) => set({ isVisible }),
  }))
);

export default useContactUsModalStore;
