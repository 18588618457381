import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface TopbarStoreState {
  progress: number;
  isProgressBarVisible: boolean;
  setProgress: (value: number) => void;
  setIsProgressBarVisible: (value: boolean) => void;
}

const useTopbarStore = create<TopbarStoreState>()(
  devtools((set) => ({
    progress: 0,
    isProgressBarVisible: false,
    setProgress: (value: number) => set({ progress: value }),
    setIsProgressBarVisible: (value: boolean) =>
      set({ isProgressBarVisible: value }),
  }))
);

export default useTopbarStore;
